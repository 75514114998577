export class GetTaxTermType {
    constructor()
    {
        this.taxTermType = {};
    }

    getTaxonomyTermType(e)
    {
        this.element = e ? e.currentTarget : false;
        let bodyClasses = $('body').attr('class');
        bodyClasses = bodyClasses.split(' ');
        const $productsType = $(this.element).closest('#products').attr('data-type') || $(document).find('#products').attr('data-type');
        for (let i = 0; i < bodyClasses.length; i++) {
            if (bodyClasses[i].match(/^tax-/)) {
                this.taxTermType['taxonomy'] = bodyClasses[i].match(/^tax-/).input.replace('tax-','');
            }

            if (bodyClasses[i].match(/^term-/)) {
                this.taxTermType['term'] = bodyClasses[i].match(/^term-/).input.replace('term-','');
            }

            if (bodyClasses[i].match(/^wensenlijst/) || $productsType === 'wishlist') {
                this.taxTermType['type'] = 'wishlist';
            }

            if (bodyClasses[i].match(/^verkoperspagina/)) {
                this.taxTermType['type'] = 'seller';
            }

            if ($productsType === 'recently') {
                this.taxTermType['type'] = 'recently';
            }

            if ($productsType === 'related') {
                this.taxTermType['type'] = 'related';
            }

            if ($productsType === 'cross-sell') {
                this.taxTermType['type'] = 'cross-sell';
            }
        }

        return this.taxTermType;
    }
}