import {Toast} from './components/toast';
window.toast = new Toast();
import {LoadTotals} from './helpers/load-totals';
window.totals = new LoadTotals();
import {GetTaxTermType} from './helpers/get-tax-type';

if ($(document).find('#products')) {
    window.getTaxTermType = new GetTaxTermType();
}

import './actions/live-search';
