import {debounce} from '../helpers/debounce';

export class Toast {
    constructor(toastClass, toastText, toastTime)
    {
        this.toast = false;
        this.toastClass = toastClass;
        this.toastText = toastText
        this.toastTime = toastTime || 3000;
    }

    toastEvents(toastTime)
    {
        setTimeout(() => {
            $(document).find('.toast').removeClass('is-visible');
            setTimeout(debounce(() => {
                $(document).find('.toast').remove();
            },500),300);
        }, toastTime);
    }

    createToast(toastClass, toastText, toastTime)
    {
        let icon = '';
        switch (toastClass) {
            case 'toast--notice':
                icon = 'info';
                break;
            case 'toast--success':
                icon = 'check-circle';
                break;
            case 'toast--error' :
                icon = 'error';
                break;
        }
        this.toast = '<div class="toast ' + toastClass + '"><span class="icon icon--' + icon + '"></span>' + toastText + '</div>';
        if (this.toast !== null && this.toast !== undefined) {
            $(document).find('.toast').remove();
        }

        $('body').prepend(this.toast);

        setTimeout(() => {
            $(document).find('.toast').addClass('is-visible');
            this.toastEvents(toastTime || this.toastTime);
        }, 300);
    }

    clear()
    {
        $(document).find('.toast').remove();
    }
}
