export class LoadTotals {
    constructor()
    {
    }

    loadTotals(productId, data, action = '')
    {
        const
            $favoriteCount = $('.js-favorite-count'),
            $cartCount = $('.js-cart-count'),
            $archiveCount = $('.js-archive-count'),
            $specificProductId = productId ? $('#product_' + productId) : false;

        $favoriteCount.text(data.wishlistCount);
        $cartCount.text(data.cartCount);

        if ($archiveCount.length) {
            $archiveCount.text(data.archiveCount);
        }

        if ($specificProductId && $specificProductId.find('.loader').hasClass('is-visible')) {
            $specificProductId.find('.loader').removeClass('is-visible');
        }
    }
}