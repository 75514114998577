class LiveSearch {
    constructor()
    {
        this.getEvents();
    }

    getEvents()
    {
        $(document).on('click', '.js-toggle-mobile-menu', (e) => {
            const
                $headerMenu = $(document).find('.header__menu'),
                $body = $(document).find('body'),
                headerTopHeight = $(document).find('.header__top').outerHeight(),
                headerContentHeight = $(document).find('.header__content').outerHeight(),
                headerFooterHeight = $(document).find('.header__footer').outerHeight(),
                headerHeight = headerTopHeight + headerContentHeight + headerFooterHeight + 1;

            $(e.currentTarget).toggleClass('is-active');
            $headerMenu.toggleClass('is-visible');
            $headerMenu.css('top', headerHeight + 'px');

            if ($headerMenu.hasClass('is-visible')) {
                $body.addClass('has-overlay');
            } else {
                $body.removeClass('has-overlay');
            }
        });

        $(document).on('click', '.js-go-back', (e) => {
            e.preventDefault();
            $(e.currentTarget).closest('.menu').removeClass('is-selected');
            $(e.currentTarget).closest('.menu__sub-list').removeClass('is-visible');
        });

        $(document).on('click', '.js-has-sub > a', (e) => {
            if ($('.js-toggle-mobile-menu').is(':visible')) {
                e.preventDefault();
                $(e.currentTarget).closest('.menu').addClass('is-selected');
                $(e.currentTarget).next('.menu__sub-list').addClass('is-visible');
            }
        });

        $(document).on('keyup', '.js-live-search', (e) => {
            e.preventDefault();
            const searchVal = $(e.currentTarget).val();
            if (e.key === 'Enter') {
                window.location.href = location.origin + '?s=' + searchVal;
            } else {
                if (searchVal === '') {
                    $(document).find('.live-search__results').remove();
                }
                this.search(e);
            }
        });
    }

    search(e)
    {
        const searchValue = $(e.currentTarget).val();
        if (searchValue && searchValue !== '') {
            $.ajax({
                dataType: 'json',
                url: resources_ajax.ajax_url,
                data: {
                    action: 'live_search',
                    searchValue: searchValue,
                    nonce: resources_ajax.nonce
                },
                method: 'post',
                success: (data) => {
                    $(document).find('.live-search__results').remove();
                    $('.live-search').append(data.html);
                },
            });
        }
    }
}

new LiveSearch();
